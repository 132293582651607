:root {
    --primary: #FFFFFF;
    --menu: #EFEFEF;
    --titles: #202020;
    --menu-background: #FFFFFF;
    --quite-dark: #4B4B4B;
    --the-red: #FD3B5D;
}

body {
    margin: 0px;
}

main {
    display: flex;
    background: #FCFCFC;
}





/* General classes */

.wrapper {
    position: absolute;
    background-color: #FFF;
    border-radius: 40px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    font-family: Futura;
    padding: 1vh;
}

.wrapper:focus {
    outline: none;
}

.input {
    background-color: #FFF;
    border-radius: 40px 40px 40px 40px;
    border: 1px solid #FFF;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    padding: 0.5vh;
    font-family: 'Fira Sans';
}

.input {
    margin-bottom: 2vh;
}

.input:focus {
    outline: none;
}

.save {
    width: 15vh;
    height: 5vh;
    flex-shrink: 0;
    border-radius: 40px !important;
    background: #43D6B9 !important;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25) !important;
    color: #FFF !important;
    border: 1px solid #43D6B9;
    font-family: 'Futura' !important;
    font-size: 1.8vh;
}

.cancel {
    width: 15vh;
    height: 5vh;
    flex-shrink: 0;
    border-radius: 40px !important;
    background: var(--the-red) !important;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    color: #FFF !important;
    border: 1px solid var(--the-red);
    font-family: 'Futura' !important;
    font-size: 1.8vh;
}

hr {
    height: 2px;
    background: #BDBDBD;
    border: none;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: #BDBDBD; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* Color of the scrollbar thumb on hover */
}








menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--menu-background);
    border-radius: 0px 40px 40px 0px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    gap: 10vh;
    width: 25vh;
    flex-shrink: 0;
    font-family: Futura;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.pages {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    width: 25vh;
    color: #494D5A;
}

#link_box {
    border-radius: 0px 40px 40px 0px;
}

#link_box:hover {
    background-color: rgba(180, 178, 178, 0.50);
}

.pages #link {
    display: block;
    text-decoration: none;
    color: #494D5A;
    padding-left: 5vh;
    padding-top: 3.5vh;
    width: 20vh;
    height: 7vh;
    border-radius: 0px 40px 40px 0px;
}

.box_parts #link {
    padding-top: 2vh;
}

#bogologo {
    max-width: 12vh;
    margin-right: 2vh;
    margin-top: 2vh;
    padding-left: 3vh;
}

.menu_accent {
    width: 25vh;
    height: 11vh;
    flex-shrink: 0;
    fill: #292929;
    filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.25));
}

.content {
    position: relative;
    width: 87.5%;
    bottom: 0;
    left: 25vh;
}

.card {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 38vh;
    top: 0;
    right: 0;
    margin-right: 27px;
    margin-top: 27px;
    border-radius: 40px !important;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20) !important;
    backdrop-filter: blur(20px) !important;
}

.card_header {
    display: flex;
    flex-direction: column;
    position: relative;
    /* right: 12vh; */
    width: 27vh;
}

.card #change_pass {
    position: fixed;
    background-color: var(--menu-background);
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: 0;
    color: var(--quite-dark);
    font-family: Futura;
    height: 5vh;
    width: 30vh;
    font-size: 2vh;
    left: 4vh;
    text-transform: none;
    bottom: 37%;
}

.card #deco {
    position: fixed;
    background-color: var(--the-red);
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: 0;
    color: var(--menu-background);
    font-family: Futura;
    height: 5vh;
    width: 30vh;
    font-size: 2vh;
    bottom: 3vh;
    left: 4vh;
    text-transform: none;
}

#button_expand {
    position: fixed;
    right: 1vh;
}

#card_buttons {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    bottom: 15vh;
}

.card #whatsapp {
    height: 8vh;
}

.account {
    display: flex;
}



.connection-page {
    display: flex;
    flex-direction: row;
}

.connection-content {
    display: flex;
    flex-direction: column;
    margin-left: 40vh;
    margin-top: 13vh;
}

#bogologo_connec {
    height: 8vh;
    width: 20vh;
    padding-bottom: 2vh;
}

.login-wrapper {
    border-radius: 40px;
    border: 1px solid #FFF;
    background: #FFF;
    box-shadow: 7px 12px 30px -1px rgba(0, 0, 0, 0.20);
    width: 45vh;
    height: 58vh;
    flex-shrink: 0;
    
    font-family: Futura;
}

#connection-title {
    text-align: center;
    font-size: 4vh;
    margin-top: 5vh;
}

.parag-2fa {
    font-family: 'Fira Sans';
    width: 30vh;
    text-align: center;
}

#login-form {
    font-family: 'Fira Sans';
    color: #303030;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#input-title {
    padding-left: 2vh;
}

#email {
    width: 29vh;
    height: 1vh;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Fira Sans';
    font-size: 1.8vh;
    color: #303030;
    padding: 2vh;
}

#pass-form {
    margin-top: 2vh;
}

#password {
    width: 29vh;
    height: 1vh;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Fira Sans';
    font-size: 2.8vh;
    color: #303030;
    padding: 2vh;
    letter-spacing: 1vh;
}

#pwforgot {
    padding-top: 2vh;
    color: #4B4B4B;
    font-family: Fira Sans;
}

#connexion {
    width: 22vh;
    height: 7vh;
    flex-shrink: 0;
    border-radius: 40px;
    background: #0F0F0F;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: Futura;
    font-size: 2vh;
    margin-top: 7vh;
}

#reinit-content {
    margin-top: 9vh;
}

#reinit {
    height: 72vh;
}

#otp-speech {
    padding: 0vh 9vh 0vh 8vh;
    text-align: justify;
}

.accounts_list {
    display: flex;
    flex-direction: column;
    padding-left: 5vh;
    padding-top: 4vh;
    font-family: Futura;
    font-size: 2.5vh;
    height: 96vh;
}

.users_recap {
    display: flex;
    flex-direction: row;
    gap: 14vh;
    font-size: 1.5vh;
}

hr {
    width: 33vh;
    margin-left: 0;
}

#admin_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 2vh;
}

#member_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 2vh;
}

#partner_list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 2vh;
}

#usercard {
    width: 38vh;
    height: 7vh;
    border-radius: 40px;
    border: 1px solid #FFF;
    background: linear-gradient(163deg, rgba(255, 255, 255, 0.40) 4.82%, rgba(255, 255, 255, 0.20) 93.22%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    font-family: Futura;
    font-size: 2.5vh;
    color: #000;
    justify-content: left;
    padding-left: 4vh;
}

.Popup {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: whitesmoke;
    border: 2px solid black;
    padding: 32px;
    border-radius: 12px;
    transform: translate(-50%, -50%);
  }



.create_from_admin_content {
    width: 100%;
    height: 100%;
    padding-top: 12vh;
}

#create-user-form {
    border-radius: 40px;
    border: 1px solid #FFF;
    background: #FFF;
    box-shadow: 7px 12px 30px -1px rgba(0, 0, 0, 0.20);
    width: 50vh;
    height: 75vh;
    flex-shrink: 0;
    font-family: Futura;
    margin: auto;
    padding-top: 1vh;
}

.undertitle {
    font-family: "Fira Sans";
    color: #848484;
    font-size: 1.8vh;
    margin-top: 0;
    width: fit-content;
}

.undertitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

#undertitle {
    font-family: "Fira Sans";
    color: #848484;
    font-size: 2.5vh;
    margin-top: 0;
}

#undertitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

#admin-title {
    margin-bottom: 0;
    font-family: "Futura";
    font-size: 3.5vh;
}

#user-create-modal-title {
    text-align: center;
}

.form-entries {
    padding-left: 5vh;
}

.long-box {
    width: 35vh !important;
}

.long-box:focus {
    outline: none;
}

.last-long-box {
    margin-bottom: 1.5vh;
}

#user-role-drop {
    display: flex;
    flex-direction: column;
}

#input-title-dropdown {
    padding-left: 2vh;
    margin-top: 2vh;
    margin-bottom: 1.5vh;
}

#userRole {
    width: 39.4vh;
    height: 5.4vh;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Fira Sans';
    font-size: 1.8vh;
    color: #303030;
    padding-left: 2vh;
}

#drop-option {
    border-radius: 40px;
}

#create-user-button {
    margin-top: 4vh !important;
}

#info_member_modal {
    align-items: center;
    justify-items: center;
}

.info-member {
    padding: 3vh;
    height: 25vh;
    width: 40vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
}

.bigger-info {
    font-size: 2vh;
}

.gray-info {
    color: #666;
}

.manage-accounts-buttons {
    margin-top: 1.5vh;
    display: flex;
}

.delete-account {
    margin-left: auto !important;
}

#actions_add {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    gap: 4vh;
}

.base_partners {
    padding-left: 5vh;
    padding-top: 4vh;
    height: 96%;
    display: flex;
    flex-direction: column;
}

.search_base {
    height: 76vh;
}

.actions_wrapper {
    display: flex;
    flex-direction: row;
    gap: 4vh;
    margin-top: 2vh;
}

.searchbar_base {
    display: flex;
    width: 35vh;
    height: 3.5vh;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Fira Sans';
    font-size: 1.8vh;
    color: #303030;
    padding-left: 2vh;
    margin-left: auto;
    margin-right: 2vh;
}

.base_card_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2vh;
    margin-top: 2vh;
    padding-left: 0;
}

.base_card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin-top: 2vh;
    width: 22.5vh;
    align-items: center;
}

.base_card {
    display: flex;
    width: 22.5vh !important;
    height: 11vh !important;
    border-radius: 55px !important;
    border: 1px solid #FFF !important;
    background: linear-gradient(163deg, rgba(255, 255, 255, 0.40) 4.82%, rgba(255, 255, 255, 0.20) 93.22%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    font-family: Futura !important;
    font-size: 2.1vh !important;
    color: #000 !important;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
}

#pages_change {
    width: fit-content;
    height: 3vh;
    display: flex;
    flex-direction: row;
    gap: 2vh;
    margin-top: 2vh;
    padding-left: 0;
    border-radius: 20px;
    border: 1px solid #FFF;
    box-shadow: 2px 1px 10px 0px rgba(0, 0, 0, 0.25);
    color: #686868;
    font-family: Futura;
    font-size: 2vh;
    align-items: center;
    bottom: 0;
    position: relative;
    align-self: center;
    margin-top: auto;
    margin-bottom: 2vh;
}

.page_change {
    font-size: 1.5vh !important;
    color: #848484 !important;
    font-family: Futura !important;
    border-radius: 20px !important;
}

.page_change:hover {
    background-color: #FFF;
    cursor: pointer;
}

.create-partner-form-complete-wrapper {
    overflow: auto;
    font-family: Futura;
    padding-top: 1vh;
    height: 80vh;
    right: 35%;
    /* left: 50%; */
    /* transform: translate(-50%, 0%); */
}

#create-partner-form {
    overflow: auto;
    margin: auto;
    padding-right: 2vh;
    height: 80vh;
    width: 50vh;
}



.create-partner-dropdown {
    margin-top: 1.5vh !important;
}

#create_buttons {
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
}

.cancel-create-partner {
    width: 22vh;
    height: 7vh;
    flex-shrink: 0;
    font-size: 2vh !important;
    margin-top: 7vh !important;
    margin-left: 2vh !important;
    margin-right: 3vh !important;
}

#partner_create_contact_title {
    margin-top: 4vh;
}

.page_replace_turn {
    width: 7vh;
    border-radius: 20px;
}




/* Filters */

.filters {
    position: absolute;
    height: 99%;
    right: 0;
    margin-top: -4vh;
    margin-right: 0;
    background-color: #FFF;
    border-radius: 40px 0px 0px 40px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    font-family: Futura;
    width: 37vh;
    padding: 1vh;
}

#filtres-title-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#filtres-title {
    font-size: 3.5vh;
    margin-top: 2vh;
    margin-left: 2vh;
    margin-bottom: 0;
    color: #202020;
}

#filtres-title-hr {
    width: 29vh;
    height: 2px;
    background: #BDBDBD;
    border: none;
    margin-left: auto;
}

#filters-wrapper {
    margin-left: 3.5vh;
}

.filtres-undertitle {
    color: #494D5A;
    margin-bottom: 1vh;
    margin-top: 2vh;
}

.filtres_options {
    display: flex;
    flex-direction: row;
    gap: 2vh;
    font-family: 'Fira Sans';
    color: #848484;
    font-size: 1.4vh;
    margin-top: -1.8vh;
    margin-bottom: -1vh;
}

.filtres_select_all:hover {
    text-decoration: underline;
    cursor: pointer;
}

.filtres_erase:hover {
    text-decoration: underline;
    cursor: pointer;
}

.searchbar_filtres {
    display: flex;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Fira Sans';
    font-size: 1.8vh;
    color: #303030;
    padding-left: 2vh;
    width: 30vh;
    height: 4vh;
}

.filtres-hr {
    width: 20vh;
    height: 1px;
    background: #BDBDBD;
    border: none;
}

#filtres_buttons {
    margin-top: 4vh;
}

#erase-all-filters {
    display: flex;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFF;
    border-radius: 40px;
    font-family: 'Futura';
    font-size: 1.8vh;
    color: #494D5A;
    width: 100%;
    height: 5vh;
    margin-top: 2vh;
}

#filtres_buttons_colored {
    display: flex;
    flex-direction: row;
    gap: 1vh;
    margin-top: 2vh;
}

#filtres-cancel {
    display: flex;
    flex-shrink: 0;
    background: #FD3B5D;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FD3B5D;
    border-radius: 40px;
    font-family: 'Futura';
    font-size: 1.8vh;
    color: #FFF;
    width: 49%;
    height: 5vh;
}

#filtres-validate {
    display: flex;
    flex-shrink: 0;
    background: #43D6B9;
    box-shadow: 2px 1px 13px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #43D6B9;
    border-radius: 40px;
    font-family: 'Futura';
    font-size: 1.8vh;
    color: #FFF;
    width: 49%;
    height: 5vh;
}

#typeselect {
    width: 32.5vh;
    height: 4.3vh;
}


/* Details */

#loading-partner {
    font-family: Futura;
    color: #666;
    font-size: 5vh;
    position: relative;
    margin-left: 70vh;
    margin-top: 50vh;
    
}

.partner_page {
    padding-left: 5vh;
    padding-top: 4vh;
    display: flex;
    flex-direction: column;
    font-family: Futura;
    height: fit-content;
}

#details_title_name {
    font-size: 5vh;
    color: #202020;
    margin-bottom: 0;
}

.details_contact_subtitle {
    margin-top: 3vh;
    margin-bottom: 1.5vh;
}

#details_info_content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 150vh;
    height: 23vh;
    color: #666;
    font-size: 2vh;
    border-radius: 30px;
    padding-top: 3vh;
    padding-left: 2vh;
}

.details_info_content_top {
    display: flex;
    flex-direction: row;
}

.details_info_content_left {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.details_info_content_right {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    margin-left: auto;
    margin-right: auto;
}

#details_info_content_logo {
    position: relative;
    border-radius: 30px;
    width: 15vh;
    height: 15vh;
    margin-right: 2vh;
    padding: 0 !important;
}

.uploaded-image {
    width: 15vh;
    height: 15vh;
    border-radius: 30px;
}

.uploaded-image:hover {
    cursor: pointer;
}

.input-modif {
    margin-left: 0.5vh;
    border: none;
    background-color: #FFF;
    color: #666;
    font-family: 'Fira Sans';
    font-size: 1.7vh;
    font-weight: 600;
    width: 28vh;
    resize: none;
}

.details_info_content_bottom {
    margin-left: auto;
    gap: 1.5vh;
}

#details_info_buttons {
    display: flex;
    flex-direction: row;
    gap: 2vh;
}

.partner-website-link {
    color: #666;
    text-decoration: underline;
}

#more_partner_info {
    text-decoration: underline;
    color: #666;
}

#more_partner_info:hover {
    cursor: pointer;
}

#partner_info_modal {
    width: 150vh;
    height: 56vh;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
    bottom: 0;
    border-radius: 40px 40px 0px 0px;
}

.partner_info_modal_content {
    padding-left: 2vh;
}

#partner_info_modal_textarea {
    width: 144vh;
    height: 35vh;
    resize: none;
    overflow: auto;
}

.partner_info_modal_buttons {
    position: relative;
    width: fit-content;
    margin-left: auto;
}

#save_partner_info {
    margin-left: 1.5vh;
    margin-right: 1vh;
}

#closing_arrow_info_modal {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
}

#closing_arrow_info_modal:hover {
    cursor: pointer;
}



#cancel-modif-partner {
    height: 3.5vh;
    width: 12vh;
    font-size: 1.5vh;
    margin-right: 1vh;
}

#save-modif-partner {
    height: 3.5vh;
    width: 12vh;
    font-size: 1.3vh;
}

/* Partner's contacts */
.details_contact_content {
    overflow-x: auto;
    white-space: nowrap;
    padding-left: 2vh;
    margin-left: -2vh;
}

#add_contact_button {
    margin-bottom: 0vh;
}

.contacts_list {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 1.5vh;
    padding-left: 0;
    
}

.contact_card {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100vh;
    color: #666;
    font-size: 2vh;
    border-radius: 30px;
    padding-top: 3vh;
    padding-left: 2vh;
    padding-bottom: 3vh;
}

.contact_card_left {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.contact_card_right {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
}

.contact_card_buttons {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: -1vh;
}

.delete_contact {
    margin-right: 0;
    margin-left: 1vh !important;
}

#confirm_delete_contact_modal {
    width: 60vh;
    height: 15vh;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 200%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh;
}

.confirm-delete-buttons {
    margin-top: 2vh;
}

.confirm-delete-contact-button {
    margin-right: 2vh !important;
    border-radius: 40px !important;
    width: 15vh !important;
    height: 5vh !important;
    font-size: 1.8vh !important;
    font-family: Futura !important;
    color: #666 !important;
    flex-shrink: 0 !important;
    border: 1px solid #FFF !important;
}

.save_contact {
    background-color: #666 !important;
}

.modify_date_modal {
    width: 40vh;
    height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
}

.modify-date-buttons {
    margin-top: 3vh;
}

.modify-date-button1 {
    margin-right: 1vh !important;
}

.modify-date-button2 {
    margin-left: 1vh !important;
}

/* Creating a new contact  */
.contact-creation-box {
    position: fixed;
    width: 50vh;
    height: 90vh;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
}

.contact-creation-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-creation-title {
    font-size: 3.5vh;
    margin-bottom: 2vh;
}

.contact-creation-hr {
    margin-left: 8vh;
    margin-bottom: 4vh;
    margin-top: 0;
}

.contact-creation-body {
    padding-left: 4vh;
    padding-right: 4vh;
}

.contact-creation-group {
    display: flex;
    flex-direction: column;
}

.contact-creation-label {
    margin-bottom: 1vh;
    font-size: 1.8vh;
}

.contact-creation-input {
    height: 3vh;
    padding-left: 2vh;
}

.contact-creation-buttons {
    margin-top: 2vh;
    margin-left: 4vh;
    margin-right: 4vh;
}

.contact-creation-validate {
    margin-left: 12vh !important;
}



.details_actions_subtitle_buttons {
    display: flex;
    flex-direction: row;
    gap: 2vh;
    margin-top: 1vh;
    margin-bottom: 0;
}

/* Create action */

#create_action_wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: -4vh;
    align-self: center;
    margin-right: 2.5vh;
    width: 120vh;
    padding-left: 3vh;
    padding-bottom: 2vh;
    border-radius: 0px 0px 40px 40px;
}

#create_action_title_wrapper {
    margin-bottom: 3vh;
}

#create_action_title {
    margin-bottom: 1vh;
}

#create_action_form_wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 2vh;
    color: #666;
    font-family: 'Fira Sans';
    font-weight: bold;
    font-size: 1.8vh;
}

#labels {
    display: flex;
    flex-direction: column;
    gap: 2.6vh;
    margin-right: 2vh;
}

#inputs {
    display: flex;
    flex-direction: column;
}

#actionformtitle {
    width: 90vh;
}

#actionformcontact {
    width: 90vh;
}

#actionformsubject {
    width: 90vh;
    height: 10vh;
    border-radius: 20px;
    resize: none;
}

#actionlabelsubject {
    margin-bottom: 8.5vh;
}

#actionformoperation {
    width: 90vh;
}


#create_action_choosedate {
    color: #666;
}

#create_action_buttons {
    display: flex;
    flex-direction: row;
    right: 0;
    gap: 2vh;
}

#cancel_create_action {
    margin-left: auto;
    margin-right: 0;
    z-index: 0;
}

#save_create_action {
    margin-right: 2vh;
    z-index: 0;
}

#actionformopchoice {
    z-index: 2;
}

#actionformdatechoice {
    z-index: 1;
}



.details_actions_content {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-left: -2vh;
    margin-top: -1vh;
    padding-left: 2vh;
}

.actions_list {
    padding-left: 0;
    display: flex;
    flex-direction: row;
}

/* Action card */
#action_card {
    position: relative;
    color: #666;
    font-family: 'Fira Sans';
    border-radius: 30px;
    width: 50vh !important;
    height: 8vh;
    margin-right: 2vh;
}

#action_card:hover {
    cursor: pointer;
}

#action_card_title {
    font-size: 2.5vh;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 1vh;
}

.action_card_infos {
    display: flex;
    flex-direction: row;
}

.action_card_right {
    display: flex;
    flex-direction: column;
    margin-left: 2vh;
}

.action_card_left {
    display: flex;
    flex-direction: column;
    margin-left: 1vh;
}


/* Operations display */
.details_operations_buttons {
    display: flex;
    flex-direction: row;
}

.operations_time_buttons {
    background-color: #FFF;
    color: #666 !important;
    border-radius: 40px 40px 40px 40px !important;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20) !important;
    backdrop-filter: blur(20px) !important;
    font-family: 'Fira Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    padding: 1vh !important;
    width: 12vh !important;
    text-transform: none !important;
    margin-right: 2vh !important;
    padding: 0.2vh !important;
}

#searchbar_operations {
    padding: 0;
    padding-left: 1vh;
    font-size: 0.875rem;
    height: 2.8vh;
    margin-right: 18vh;
}

.searchbar_base:focus {
    outline: none;
}

#details_operations_content {
    position: relative;
    width: 150vh;
    height: 60vh;
    margin-top: 2vh;
    margin-bottom: 4vh;
    overflow: auto;
}



/* History */
#history {
    position: fixed;
    height: 98%;
    width: 35vh;
    right: 0;
    margin-top: -4vh;
    border-radius: 40px 0px 0px 40px;
}

#history-header {
    display: flex;
    flex-direction: column;
    font-size: 3vh;
    align-items: center;
    margin-top: -2vh;
}

#history-underline {
    width: 18vh;
    margin-right: 0;
    margin-top: -2vh;
}

#history-body {
    height: 88vh;
    display: flex;
    flex-direction: row;
    align-content: center;
}

#history-list {
    padding-left: 0;
}

#close-history {
    margin-top: auto;
    margin-bottom: auto;
    width: 1.5vh;
    height: 4vh;
}

#close-history:hover {
    cursor: pointer;
}

#history-item {
    position: relative;
    border-radius: 20px;
    width: 30vh;
    height: 10vh;
    color: #676767;
    font-weight: 700;
    padding-left: 2vh;
    margin-bottom: 2vh;
}

#history-item:hover {
    cursor: pointer;
}

.history-item-title {
    margin-top: 0;
    margin-bottom: 0.6vh;
}

.history-item-content {
    font-size: 2vh;
}

.history-item-operation {
    margin-top: 0;
    margin-bottom: 0;
}

.history-item-date {
    margin-top: 0.3vh;
}


/* Notificaions */
.notif-wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    background-color: #FFF;
    height: 8vh;
    margin-bottom: 1vh;
    width: 50%;
    overflow-x: hidden;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    margin-left: 50vh;

    .notifText {
        height: 7vh;
        color: #494D5A;
        font-family: 'Fira Sans';
        font-size: 2vh;
        justify-content: center;
        margin-top: 2.5vh;
    }

    .redBar {
        width: 100%;
        background-color: #FD3B5D;
        height: 1vh;
    }

    .greenBar {
        width: 100%;
        background-color: #43D6B9;
        height: 1vh;
    }

}

/* Operations display partners */

#operations_present {
    background-color: #666666;
    color: #FFF !important;
}

.operations_list {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin-top: 2vh;
    padding-left: 0;
    align-items: center;
}

.operation_card {
    position: relative;
    border-radius: 50px;
    width: 95% !important;
    height: 6vh;
    margin-right: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666;
    font-size: 2.2vh;
}

.operation_card:hover {
    cursor: pointer;
}

.operation_card_title {
    margin-left: 2vh;
}

#operation_card_date {
    margin-left: auto;
    margin-right: 2vh;
}

/* Operation linking modal */
#link_op_modal {
    position: fixed;
    width: 55vh;
    height: 85vh;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 7%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link_op_modal_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#hr_op_assign {
    margin-top: -1vh;
    margin-bottom: 1.5vh;
    margin-left: 8vh;
}

#searchbar_operations_assign {
    width: 35vh;
    margin-left: 6vh;
    margin-bottom: -2vh;
    margin-top: 2vh;
}

.link_op_modal_text {
    overflow: auto;
    padding: 3vh;
    height: 57vh;
    width: 45vh;
}

.operationLink_card {
    height: 5vh;
}

.operationLink_card_title {
    font-size: 2.4vh;
}

.link_op_modal_buttons {
    display: flex;
    flex-direction: row;
}

#save_link_op {
    margin-left: auto;
}



/* Action details element */
.details_action_wrapper {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    height: 98%;
    border-radius: 40px 0px 0px 40px;
    color: #4B4B4B !important;
    width: 40vh;
}

.action-detais-wrapper2 {
    height: 94vh;
}

.details_action_title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details_action_hr {
    width: 25vh;
    margin-left: 7vh;
}

.details_action_wrap {
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
}

.details_action_body {
    margin-left: 1vh;
}

.action-details-field {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.action-deatils-field2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.action-details-big {
    font-size: 2.2vh;
    margin-left: 1vh;
    margin-top: 0.5vh;
}

.action-details-smaller {
    font-size: 1.9vh;
    resize: none;
    margin-top: -1vh;
    margin-left: 0vh;
    width: 36vh;
}

.action-details-modif {
    margin-right: 0;
    margin-left: auto;
    margin-top: 2.5vh;
}

.action-details-subject {
    display: flex;
    flex-direction: column;
}

.action-details-subject-title {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.action-details-subject-label {
    margin-top: 1.5vh;
    left: 0;
}

.action-details-stage-title {
    margin-bottom: -2vh;
}

#details_action_buttons {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    margin-top: 4vh;
    margin-bottom: 0;
    align-items: center;
    bottom: 0;
}

#upload_details_action {
    width: 90%;
    color: #666 !important;
    background-color: #FFF !important;
}

#next_stage_details_action {
    width: 90%;
}

#delete_details_action {
    width: 90%;
}

.whole-width {
    width: 36vh !important;
    display: flex;
    flex-direction: row;
}

.stage-in-action {
    margin-left: 1vh;
}

.actions-details-loading {
    height: 90%;
    width: 38vh;
}

.actions-details-loading-text {
    position: relative;
    margin-left: 30% !important;
    margin-top: 50vh !important;
    font-size: 2.5vh !important;
}

.cancel-white {
    color: #666 !important;
    background-color: #FFF !important;
}

.confirm-users-boxes {
    width: 40vh;
    height: 25vh;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3vh;
}

.manage-accounts-buttons2 {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    width: 38vh;
}

.confirm-user-action-pw {
    width: 30vh;
    height: 3vh !important;
}

.confirm-user-action-pw-admin {
    width: 30vh;
    height: 3vh !important;
    margin-bottom: 0;
}


/* Operation details component */
.operations_details {
    position: fixed;
    z-index: 3;
    bottom: 0;
    height: 70%;
    width: 140vh;
    right: 50%;
    left: 50%;
    transform: translate(-43%, 0%);
    padding-left: 5vh;
    padding-right: 5vh;
    border-radius: 40px 40px 0px 0px;
}

.close-ops-details {
    margin-left: 48%;
}

.close-ops-details:hover {
    cursor: pointer;
}

.operations-details-title {
    font-size: 3.5vh;
    margin-bottom: 2vh;
    margin-top: 1vh;
}

.ops-details-undertitles {
    display: flex;
    flex-direction: row;
    gap: 2vh;
}

.ops-details-notes {
    color: #4B4B4B !important;
    width: 80%;
    margin-left: 0;
}

.ops-details-body {
    color: #4B4B4B;
}

.ops-details-budget {
    margin-bottom: -1vh;
    display: flex;
    flex-direction: row;
}

.ops-details-budget-content {
    font-size: 2.8vh !important;
    margin-top: 1.7vh;
    color: #4B4B4B !important;
    height: 4vh;
}

.ops-details-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -1vh;
}

.ops-details-dates-label {
    margin-right: 1vh;
}

.ops-details-dates-content {
    font-size: 2.2vh;
    margin-top: 0.5vh;
}

.ops-details-buttons {
    display: flex;
    flex-direction: row;
    gap: 10%;
    width: 90%;
    margin-left: 3%;
}

.ops-details-button {
    width: 35vh !important;
    height: 6vh !important;
    font-size: 1.8vh !important;
}

.yellow-button {
    background-color: #FFC03F !important;
}

.cannot-be-pressed {
    width: 25vh !important;
    height: 5vh !important;
    font-size: 1.6vh !important;
    margin-top: 0.5vh !important;
}

.cannot-be-pressed:hover {
    cursor: not-allowed;
}



/* Operation creation component */
.create-op-wrapper {
    top: 0 !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 0vh;
    margin-right: 2.5vh;
    width: 120vh;
    padding-left: 3vh;
    padding-bottom: 2vh;
    border-radius: 0px 0px 40px 40px;
    right: 50%;
    left: 50%;
    transform: translate(-39%, 0%);
}

#create_operation_buttons {
    display: flex;
    flex-direction: row;
    right: 0;
    gap: 2vh;
    margin-top: 2vh;
}

.operationformdatechoice {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

#operationlabelsubject {
    margin-bottom: 10.5vh;
}

#opcreationdatelabel1 {
    margin-bottom: 3.5vh;
}


/* Partners list in ops details */
.display-participating {
    height: 80vh;
    width: 60vh;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.display-participating-wrapper {
    height: 72vh;
    width: 56vh;
    margin-bottom: 2vh;
    overflow: auto;
}

.display-participating-list {
    padding-left: 2vh !important;
    padding-right: 2vh;
    overflow: auto;
    text-align: center;
}

.long-hr {
    width: 100%;
}